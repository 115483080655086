import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

// Components
import Layout from '../components/Layout';
import Seo from '../components/SEO';
import Section from '../components/Section';
import Container from '../components/Container';
import SectionHeading from '../components/SectionHeading';
import CardTypeOne from '../components/CardTypeOne';
import CardTypeTwo from '../components/CardTypeTwo';
import ProgressList from '../components/ProgressList';
// import BannerCTA from '../components/BannerCTA';

const ourApproach = [
  {
    title: 'Cobuilding',
    description:
      'We let you work with our designers and engineers in order to ensure the best product for the end-users. Cobuilding is a huge plus if you are a non-tech founder as you get to learn and get familiar with design, technology, process, management - almost everything.',
  },
  {
    title: 'Quality',
    description:
      'All the codes and designs we do are at par with global standards. We make pixel perfect designs and scalable product architecture so you feel good about your product.',
  },

  {
    title: 'Flexibility',
    description:
      'We care about your products and expectations. We make sure to be very flexible with our services, terms, processes so you can have a better experience while working with us.',
  },
  {
    title: 'Transparency',
    description:
      'We believe in fair and transparent services. We communicate and keep things upfront so timelines and deliverables can be met as per our commitment.',
  },
  {
    title: 'Ownership',
    description:
      'You have all the rights over the codes, designs, documents we create for you. Also, as part of our practices we make sure to keep your product confidential.',
  },
  {
    title: 'Support',
    description:
      'Quick support as and when required. We extend a few weeks of free support so the initial set of bugs and errors can be taken care of.',
  },
];

const approachStrategies = [
  {
    title: 'Discover',
    description:
      'Our product team embeds with you to understand your product, requirements, market, competitions, and your expectations attached to the product.',
    listItems: [
      'Product & Requirements',
      'Product Expectations',
      'Market & Competitions',
      'Pre & Post Product Support',
    ],
  },
  {
    title: 'Strategize',
    description:
      'Our team helps you design a product roadmap with milestones so you can prioritize what to build first and what to build next.',
    listItems: [
      'Product Roadmap',
      'Design Approach',
      'Product Technology',
      'Development Approach',
    ],
  },
  {
    title: 'Build',
    description:
      'Our team coworks with yours in order to ensure the right product for the end-users.',
    listItems: [
      'UX, UI and Prototyping',
      'Product Development',
      'Testing & Deployment',
      'Product Support',
    ],
  },
  {
    title: 'Scale',
    description:
      'Updates and improvements are part of the product process and hence we do an analysis of the product and build the next round of products accordingly.',
    listItems: [
      'Product Analysis',
      'Product Optimization',
      'Next Set of Features',
      'Product 2.0 & so on',
    ],
  },
];

const designSprint = [
  {
    title: 'Research & Study',
    description:
      'We map out the product/project challenges & create a shared understanding of the product.',
  },
  {
    title: 'Mind Mapping & Flow',
    description:
      'We generate a broad range of ideas, narrow them down and determine what to keep and skip.',
  },
  {
    title: 'Low & High Fed Wireframes',
    description:
      'Flows are converted into wireframes and then UX is locked and the UI team takes over.',
  },
  {
    title: 'Branding, UI & Graphics',
    description:
      'Interactions and Graphics are implemented after doing a UI test with branding elements in place.',
  },
  {
    title: 'Prototyping & Documentation',
    description:
      'A clickable prototype is made out of finalized UI designs. Design documentation & video is prepared and then handed over to the development team.',
  },
];

const devSprint = [
  {
    title: 'Research & Sprint Planning',
    description:
      'Dev team gets a design walkthrough and initiates their research on unique UI elements, 3rd Party APIs, techs, and much more. Sprints are planned and resources are deployed.',
  },
  {
    title: 'Product Development & API Integrations',
    description:
      'Modules are developed and delivered as per the planned sprints. APIs are tested and integrated in the applications.',
  },
  {
    title: 'DevOps, Security & Compliances',
    description:
      'Cloud services, security and compliances are taken care of when the product is in the development stage.',
  },
  {
    title: 'Testing & Deployment',
    description:
      'Product Builds are tested with both manual and automated testing processes. Once a product is ready to ship, we deploy it on server and app stores.',
  },
  {
    title: 'Product Support',
    description:
      'As part of our services, we extend a few weeks of free support so any bug and error is well taken care of at the initial stage.',
  },
];

const Approach = ({ location }) => {
  const [selectedSprint, setSelectedSprint] = useState('design');

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "approach-page-banner.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 3080, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  const imageData = data.desktop.childImageSharp.fluid;

  const sources = [
    { aspectRatio: 1, src: '', srcSet: '' },
    { ...imageData, media: '(min-width: 1024px)' },
  ];

  return (
    <Layout pageId="approach" location={location} footer={true}>
      <Seo title="Approach" />

      <Section id="hero" backgroundImage={sources}>
        <Container
          className="flex justify-start items-center pb-0 lg:pb-0 xl:pb-0"
          screenHeight
        >
          <div className="hero-content">
            <div className="my-0" style={{ maxWidth: 610 }}>
              <h1 className="ff-tertiary fs-46 fs-lg-48 fw-500 my-0">
                <span className="text-primary fw-700">Build</span> and{' '}
                <span className="text-primary fw-700">Scale</span> Your Product
                Exponentially!
              </h1>
            </div>
            <div className="fw-400 fs-16 mt-12" style={{ maxWidth: 493 }}>
              Be it a simple or a highly complex product, we get it shipped in a
              matter of weeks.
            </div>
          </div>
        </Container>
      </Section>

      <Section id="tailored-approach" backgroundColor="#FFF">
        <Container className="flex-col">
          <SectionHeading style={{ maxWidth: 975 }}>
            <h2 className="fw-300 fs-36 fs-lg-48 text-black">
              Tailored <span className="text-primary fw-400">Approach</span> to
              Meet Your Expectations
            </h2>
          </SectionHeading>
          <div className="grid-container">
            {ourApproach.map((data, index) => (
              <div key={index}>
                <CardTypeOne data={data} index={index + 1} />
              </div>
            ))}
          </div>
        </Container>
      </Section>

      <Section id="approach-strategies">
        <Container className="flex-col">
          <SectionHeading style={{ maxWidth: 975 }}>
            <h2 className="fw-300 fs-36 fs-lg-48">
              <span className="text-primary fw-400">Process</span> at HQ
            </h2>
          </SectionHeading>
          <div className="grid-container">
            {approachStrategies.map((data, index) => (
              <div key={index}>
                <CardTypeTwo data={data} theme="dark" />
              </div>
            ))}
          </div>
        </Container>
      </Section>

      <Section id="sprint-planning" backgroundColor="#FFF">
        <Container className="flex-col">
          <SectionHeading style={{ maxWidth: 975 }}>
            <h2 className="fw-300 fs-36 fs-lg-48 text-black">
              <span className="text-primary fw-400">Methodology</span> - Agile
              and Sprint
            </h2>
          </SectionHeading>
          <div className="hidden lg:block">
            <div className="grid grid-cols-1 lg:grid-cols-2">
              <div className="list-container">
                <span>
                  <h3 className="list-title">Design Sprints</h3>
                  <ProgressList list={designSprint} />
                </span>
              </div>
              <div className="list-container">
                <span>
                  <h3 className="list-title">Development Sprints</h3>
                  <ProgressList list={devSprint} />
                </span>
              </div>
            </div>
          </div>
          <div className="block lg:hidden">
            <div className="list-container">
              <div className="flex justify-center items-center flex-nowrap">
                <span
                  className={`flex-1 list-title cursor-pointer ${
                    selectedSprint === 'design' ? 'active-tab' : ''
                  }`}
                  onClick={() => setSelectedSprint('design')}
                >
                  Design Sprints
                </span>

                <span
                  className={`flex-1 list-title cursor-pointer ${
                    selectedSprint === 'dev' ? 'active-tab' : ''
                  }`}
                  onClick={() => setSelectedSprint('dev')}
                >
                  Development Sprints
                </span>
              </div>
              <div>
                {selectedSprint === 'design' && (
                  <ProgressList list={designSprint} />
                )}
                {selectedSprint === 'dev' && <ProgressList list={devSprint} />}
              </div>
            </div>
          </div>

          {/* <div>
            <BannerCTA
              title="Our Portfolio"
              subTitle="Projects & Case studies."
              navigateText="Navigate"
              navigateLink="/products"
            />
          </div> */}
        </Container>
      </Section>
    </Layout>
  );
};

Approach.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default Approach;
