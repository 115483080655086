import React from 'react';
import PropTypes from 'prop-types';

const ProgressList = ({ list }) => {
  return (
    <ol className="progress-list">
      {list.map((item, index) => (
        <li key={index}>
          <div className="title">{item.title}</div>
          <div className="description">{item.description}</div>
        </li>
      ))}
    </ol>
  );
};

ProgressList.propTypes = {
  list: PropTypes.array,
};

ProgressList.defaultProps = {
  list: [],
};

export default ProgressList;
